/* CSS */
import "@assets/scss/all.scss";

/* JS */
import "@assets/js/lib/limet/jquery.cookies";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'lazysizes';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons); // loads the Icon plugin


 // Init jquery cookies
 $('#cookie-consent').cookies();


//function to check, if element is in visible screen area
(function($) {
    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };
})(jQuery);

$(document).ready(function() {
    $(window).on('scroll resize', function() {
        $('footer').each(function() {
            if ($(this).isInViewport()) {
                $("header").addClass("hide-logo");
                //console.log('Footer ist im sichtbaren Bereich.');
            } else {
                $("header").removeClass("hide-logo");
                //console.log('Footer ist NICHT im sichtbaren Bereich.');
            }
        });
    });

    // Initiale Prüfung beim Laden der Seite
    $(window).trigger('scroll');
});


/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}